@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 600;
  src: url('./media/Futura/FuturaPTBold.otf'); 
}

@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 500;
  src: url('./media/Futura/FuturaPTHeavy.otf'); 
}

@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 400;
  src: url('./media/Futura/FuturaPTDemi.otf'); 
}

@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 300;
  src: url('./media/Futura/FuturaPTMedium.otf'); 
}


@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 200;
  src: url('./media/Futura/FuturaPTBook.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Futura';
  font-style: normal;
  font-weight: 100;
  src: url('./media/Futura/FuturaPTLight.otf'); /* IE9 Compat Modes */
}

body {
  font-family: 'Futura';
  font-weight: 500;
}

h2 {
  font-family: 'Futura';
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
}

#bottom_fade {
	z-index: 99;
	position: fixed;
	bottom: 0%;
	background-image: url("./media/bottom-fade.png");
}

/*.App {
  text-align: center;
}*/

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
/* 
.App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
